// extracted by mini-css-extract-plugin
export var column = "BlogPostEdit__column__dxW9t";
export var container = "BlogPostEdit__container__pfpfq";
export var flex = "BlogPostEdit__flex__LUDQE";
export var flexColumn = "BlogPostEdit__flexColumn__r3mMw";
export var gap1 = "BlogPostEdit__gap1__WquSg";
export var gap2 = "BlogPostEdit__gap2__dWJAc";
export var gap3 = "BlogPostEdit__gap3__xbCJq";
export var gap4 = "BlogPostEdit__gap4__zEIkU";
export var gap5 = "BlogPostEdit__gap5__nR0cR";
export var pageTitle = "BlogPostEdit__pageTitle__GIKKB";
export var row = "BlogPostEdit__row__csWTL";