// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as BlogPost from "../../../models/BlogPost.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BlogPostNavbar from "../common/navbar/BlogPostNavbar.res.js";
import * as BlogPostCategory from "../../../models/BlogPostCategory.res.js";
import * as BlogPostEditForm from "./BlogPostEditForm.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogPostEditScss from "./BlogPostEdit.scss";

var css = BlogPostEditScss;

function BlogPostEdit$Component(props) {
  var blogPost = props.blogPost;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(BlogPostNavbar.make, {
                      selectedCategory: "Edit",
                      id: Caml_option.some(blogPost.id),
                      slug: blogPost.slug
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.container,
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: css.pageTitle,
                              children: "Edit Blog Post"
                            }),
                        JsxRuntime.jsx(BlogPostEditForm.make, {
                              blogPost: blogPost,
                              categories: props.categories
                            })
                      ]
                    })
              ]
            });
}

var Component = {
  make: BlogPostEdit$Component
};

function BlogPostEdit$default(props) {
  var blogPost = BlogPost.Edit.fromJs(props.blogPost);
  var categories = Belt_Array.map(props.categories, BlogPostCategory.Filter.fromJs);
  return JsxRuntime.jsx(BlogPostEdit$Component, {
              blogPost: blogPost,
              categories: categories
            });
}

var make = BlogPostEdit$Component;

var $$default = BlogPostEdit$default;

export {
  css ,
  Component ,
  make ,
  $$default as default,
}
/* css Not a pure module */
